import React from "react";
import styled from "styled-components";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: black;
  color: white;
  margin: 0;
  padding: 50px;

  table {
    max-width: 800px;

    td {
      text-align: center;
      padding: 10px 0;
    }
  }
`;

const data = [
  { team: "Morillo band", g: 20, w: 13, l: 7, efe: 383, pts: 3492 },
];

const Tournament = () => {
  const tableData = data.map((e, i) => {
    return (
      <tr key={i}>
        <td colSpan={10}>{e.team}</td>
        <td>{e.g}</td>
        <td>{e.w}</td>
        <td>{e.l}</td>
        <td>{e.efe}</td>
        <td>{e.pts}</td>
        <td>{Math.round((e.w / e.g) * 100)}%</td>
      </tr>
    );
  });

  return (
    <Main>
      <h1>Tournament</h1>
      <table>
        <thead>
          <tr>
            <th colSpan={10}>Name</th>
            <th>PJ</th>
            <th>PG</th>
            <th>PP</th>
            <th>EFE</th>
            <th>PTS</th>
            <th>AVG</th>
          </tr>
        </thead>
        <tbody>{tableData}</tbody>
      </table>
    </Main>
  );
};

export default Tournament;
