import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Title = styled.h1`
  font-weight: bold;
  background: linear-gradient(360deg, white, red, darkred);
  background-clip: text;
  color: transparent;
  font-size: 6rem;
  margin: 200px auto;
  text-align: center;
  -webkit-background-clip: text;
  width: 90%;
`;

export const AboutHeader = () => {
  const { t } = useTranslation();
  return <Title>{t("aboutHeader")}</Title>;
};
