import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Section = styled.section`
  align-items: center;
  display: flex;
  height: 900px;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;

  article {
    align-items: center;
    background: linear-gradient(to right, red, black 70%);
    color: white;
    display: flex;
    flex-flow: column wrap;
    height: 98%;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 98%;

    h1 {
      font-size: 3rem;
      font-weight: 400;
      margin: 20px 0;
      max-width: 400;
      width: 90%;
    }

    p {
      font-size: 1.3rem;
      max-width: 400px;
      width: 90%;
    }

    a {
      color: white;
    }
  }
`;

const DominoSection3 = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <article>
        <h1>{t("dominoSection3.title")}</h1>

        <video height="400" controls muted autoPlay loop>
          <source src="https://dmicano.s3.us-east-2.amazonaws.com/domino-demo.mp4" />
          <p>
            This video format is not supported by this browser. below is the
            link
            <a href="https://dmicano.s3.us-east-2.amazonaws.com/domino-demo.mp4">
              Domino Apunte demo
            </a>
          </p>
        </video>
        <p>{t("dominoSection3.description")}</p>
      </article>
    </Section>
  );
};

export default DominoSection3;
