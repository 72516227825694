import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Home from "./Screens/Home";
import NotFound from "./Screens/Notfound";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import "./firebase";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacy from "./Components/Privacy";
import Tournament from "./Screens/Tournament";
import Store from "./Screens/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div>
          <h1>Loading...</h1>
        </div>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="tournament" element={<Tournament />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="store" element={<Store />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
