import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import i18n from "../i18n";
import { onAuthStateChanged } from "firebase/auth";

const User = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [english, setEnglish] = useState(i18n.language === "en" ? false : true);

  useEffect(() => {
    onAuthStateChanged(auth, (cred) => {
      console.log(cred ? "SignedIn" : "Signed Out");
      setUser(cred);
    });
  }, []);

  return (
    <User.Provider value={{ user, setUser, english, setEnglish }}>
      {children}
    </User.Provider>
  );
};

export default UserProvider;

export const useUserContext = () => {
  return useContext(User);
};
