import React from "react";

function Notfound(props) {
  return (
    <div style={{ minHeight: "50vh", textAlign: "center" }}>
      <h1>404 Page not found.</h1>
    </div>
  );
}

export default Notfound;
