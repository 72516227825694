import React from "react";
import instagram from "../Assets/ic_instagram.png";
import facebook from "../Assets/ic_facebook.png";
import youtube from "../Assets/ic_youtube.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledFooter = styled.footer`
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  color: white;

  .row {
    display: flex;
    margin: 50px 0;
    gap: 20px;

    a {
      transition: all 500ms ease-in-out;
      &:hover {
        transform: rotate(0.5turn);
      }
    }
  }

  .row-2 {
    display: flex;
    margin: 50px 0;
    gap: 20px;

    .link {
      font-size: 15px;
      color: royalblue;
    }

    .copyright {
      font-size: 15px;
      margin-top: 0;
    }
  }
`;

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <StyledFooter>
      <div className="row">
        <a href="https://www.instagram.com/domino_apunte/">
          <img src={instagram} height="50" alt="Instagram" />
        </a>

        <a href="https://www.youtube.com/channel/UCFU7Sl2jOf0k9v3mjThTDbg">
          <img src={youtube} height="50" alt="Youtube" />
        </a>

        <a href="https://www.facebook.com/dominoapunte/">
          <img src={facebook} height="50" alt="Facebook" />
        </a>
      </div>

      <div className="row-2">
        <p className="copyright">&copy; {year} Dmicano LLC</p>
        <Link className="link" to="/privacy">
          Privacy Policy
        </Link>
      </div>
    </StyledFooter>
  );
};

export default Footer;
