import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import DominoSection1 from "./DominoSection1";
import DominoSection2 from "./DominoSection2";
import DominoSection3 from "./DominoSection3";

const Main = styled.article`
  align-items: center;
  background-color: white;
  display: flex;
  height: 900px;
  position: relative;
  padding: 0;
  margin: auto;
  width: 80vw;

  .carousel {
    display: flex;
    align-items: center;
    gap: 0;
    margin: 0;
    padding: 0;
    transform: ${({ sectionNumber }) => "translateX(-" + sectionNumber + "%)"};
    transition: ${({ sectionNumber }) =>
      sectionNumber === 0 ? "none" : "transform 2s ease-in-out"};
    min-width: 300%;
  }

  .right {
    position: absolute;
    display: ${({ sectionNumber }) => (sectionNumber > 66 ? "none" : "flex")};
    align-items: center;
    right: 13px;
    font-size: 50px;
    color: gray;
    cursor: pointer;
    z-index: 2;
  }

  .left {
    position: absolute;
    display: ${({ sectionNumber }) => (sectionNumber < 1 ? "none" : "flex")};
    align-items: center;
    left: 13px;
    font-size: 50px;
    color: gray;
    cursor: pointer;
    z-index: 2;
  }
`;

const Contain = styled.main`
  align-items: center;
  background-color: white;
  display: flex;
  margin: 0;
  height: 900px;
  overflow: hidden;
  padding: 0;
  width: 100vw;

  aside {
    background-color: gray;
    height: 100%;
    width: 10vw;
    opacity: 0.6;
    z-index: 3;
  }
`;

const DominoSection = forwardRef((props, ref) => {
  const [sectionNumber, setSectionNumber] = useState(0);

  useEffect(() => {
    let index = sectionNumber;
    let interv = null;

    interv = setInterval(() => {
      index = index <= 33.33 ? (index += 33.33) : 0;
      setSectionNumber(index);
    }, 18000);

    return () => {
      clearInterval(interv);
    };
  }, [sectionNumber]);

  const changeSlide = (dir) => {
    let state = sectionNumber;

    if (dir === "left") {
      state = state >= 33.33 ? state - 33.33 : state;
    } else {
      state = state < 33.33 * 2 ? state + 33.33 : state;
    }
    setSectionNumber(state);
  };

  return (
    <Contain>
      <aside></aside>
      <Main sectionNumber={sectionNumber}>
        <div className="left" onClick={() => changeSlide("left")}>
          <i className="fa-solid fa-chevron-left"></i>
        </div>

        <div className="carousel">
          <DominoSection3 />
          <DominoSection1 />
          <DominoSection2 />
        </div>

        <div className="right" onClick={() => changeSlide("right")}>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
      </Main>
      <aside></aside>
    </Contain>
  );
});

export default DominoSection;
