import dominoLogo from "../../Assets/domino-icon180.png";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 900px;
  margin: 0;
  padding: 0;
  width: 100%;

article {
  align-items: center;
  background: radial-gradient(red, black 50%);
  color: white;
  display: flex;
  flex-direction: column;
  height: 98%;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 98%;

  h1 {
    font-weight: 400;
    font-size: 3rem;
    margin: 50px 0;
    max-width: 400px;
    width: 90%;
  }

  h3 {
    margin: 20px 0 0;
    font-weight: bold;
  }

  p {
    font-size: 1.3rem;
    width: 90%;
    max-width: 400px;
  }

  a {
    color: white;
    cursor: not-allowed;
    padding: 20px;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(15deg);
  }
  5% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-170deg);
  }
43% {
  transform: rotate(-170deg);
}

100% {
  transform: rotate(10.05turn);
}
`;

const Rotate = styled.div`
  display: flex;

  &:hover {
    animation: 3s ease-in-out ${rotate};
  }
`;

const DominoSection1 = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <article>
        <h1>{t("dominoSection1.title")} </h1>

        <Rotate>
          <img src={dominoLogo} alt="domino apunte logo" />
        </Rotate>

        <h3>Domino Apunte</h3>
        <p>{t("dominoSection1.description")}</p>
      </article>
    </Section>
  );
};

export default DominoSection1;
