import React from "react";
import AppHeader from "../Components/AppHeader";
import styled from "styled-components";
import About from "../Components/about/About";
import DominoHistory from "../Components/DominoHistory";
import { useEffect } from "react";

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: linear-gradient(to bottom, black 50%, darkred, black);
  color: white;
  margin: 0;
  padding: 0;
`;

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Article>
      <AppHeader />
      <About />
      <DominoHistory />
    </Article>
  );
};

export default Home;
