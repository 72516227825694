import styled from "styled-components";

const Div = styled.article`
  align-items: center;
  display: flex;
  justify-conten: center;
  width: 30vw;

  h1 {
    font-size: 2.3rem;
    font-weight: bold;
    margin: 20px 0;
  }

  span {
    font-size: 0.8em;
  }
`;

const Card = ({ title = "title", desc = "desc", icon }) => {
  return (
    <Div>
      <article>
        <h1>
          {icon && <span>{icon}</span>} {title}
        </h1>
        <p>{desc}</p>
      </article>
    </Div>
  );
};

export default Card;
