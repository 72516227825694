import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { signup } from "../firebase";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000099;
  position: absolute;
  top: 0;
  left: 0;
`;

const Form = styled.form`
  background: black;
  border-radius: 5%;
  box-shadow: 0px 0px 20px darkred;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  max-width: 800px;
  padding: 30px;
  position: relative;
  width: 80vw;
  z-index: 5;

  fieldset {
    border-width: 0;
    color: gray;
    margin: 0 auto;
    position: relative;
    transition: border-width 0.5s ease;

    legend {
      opacity: 0;
      transition: opacity 0.5s ease;
    }
    input {
      width: 300px;
      background-color: black;
      color: lightgray;
      font-size: 1.3rem;
      border-width: 0;
      border-bottom-width: 1px;
      outline: none;
    }

    i {
      position: absolute;
      bottom: 10;
      right: 20px;
    }

    &:focus-within {
      border-width: 1px;
      legend {
        opacity: 1;
      }
      input {
        border-bottom-width: 2px;
      }
    }
  }

  h3 {
    margin: 0 auto;
  }

  button {
    background: royalblue;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    margin: 10px auto;
    padding: 10px 20px;
    transition: all 333ms ease;
    width: fit-content;

    &:hover {
      background: gray;
    }
  }

  .close {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    right: 15px;
    top: 15px;
  }
`;

const Signup = ({ show, onClose }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  if (!show) return null;

  const ShowPasswordIcon = () => {
    return showPassword ? (
      <i
        onClick={() => setShowPassword(!showPassword)}
        className="fa-solid fa-eye"
      ></i>
    ) : (
      <i
        onClick={() => setShowPassword(!showPassword)}
        className="fa-solid fa-eye-slash"
      ></i>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const info = {
      name: form.get("name"),
      email: form.get("email"),
      password: form.get("password"),
    };

    const user = await signup(info);
    if (user) {
      e.target.reset();
      onClose();
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <i onClick={onClose} className="fa-solid fa-close close"></i>
        <h3>{t("nav.signup")}</h3>
        <fieldset>
          <legend>Name</legend>
          <input
            autoCapitalize="words"
            id="name"
            placeholder="John Smith"
            name="name"
            required
          />
        </fieldset>
        <fieldset>
          <legend htmlFor="email">Email</legend>
          <input
            id="email"
            placeholder="your@mail.com"
            type="email"
            name="email"
            required
          />
        </fieldset>
        <fieldset>
          <legend htmlFor="password">Password</legend>
          <input
            id="passwod"
            placeholder="password"
            type={showPassword ? "text" : "password"}
            name="password"
            minLength={8}
            required
          />
          <ShowPasswordIcon />
        </fieldset>
        <button type="submit">{t("nav.signup")}</button>
      </Form>
    </Container>
  );
};

export default Signup;
