import styled from "styled-components";
import { AboutHeader } from "./AboutHeader";
import Card from "./AboutCard";
import DominoSection from "../domino/DominoSection";
import { useTranslation } from "react-i18next";

const Div = styled.div`
  background: black;
  width: 100vw;

  .row {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 100px 0;
    width: 100%;
  }

  .what-is-dominoapunte {
    font-weight: bold;
    font-size: 2.5rem;
    margin: 100px auto 0;
    text-align: center;
    width: 90%;
  }
`;

const starIcon = <i className="fa-regular fa-star"></i>;

const About = () => {
  const { t } = useTranslation();
  return (
    <Div>
      <AboutHeader />
      <DominoSection />

      <h3 className="what-is-dominoapunte">{t("whatIsDomino")}</h3>

      <div className="row">
        <Card title="500K+" desc={t("aboutCards.500k+")} />
        <Card icon={starIcon} title="4.9" desc={t("aboutCards.4.9")} />
        <Card title="#1" desc={t("aboutCards.#1")} />
      </div>
    </Div>
  );
};

export default About;
