import React from "react";
import styled from "styled-components";

const Main = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 100vw;
  background: linear-gradient(to bottom, black 50%, darkred, black);
  color: white;
  margin: 0;
  padding: 50px 0px;
  position: relative;
  min-height: 80vh;

  h1 {
    font-weight: 400;
    font-size: 3.5rem;
  }

  h2 {
    max-width: 700px;
    font-weight: 600;
    text-align: center;
  }

  .btn {
    background-color: yellow;
    color: black;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
  }

  a {
    color: royalblue;
  }
`;

const Store = () => {
  return (
    <Main>
      <h1>Marketplace</h1>
      <p>As an Amazon Associate We earn from qualifying purchases</p>
      <h2>
        El set de dominó que usamos los profesionales y que recomendamos las
        puedes obtener aqui.
      </h2>

      <a className="btn" href="https://amzn.to/3w5u9qM" rel="nofollow">
        ver en Amazon
      </a>

      <h2>Hechale un vistazo a este set de Dominó mas economico. </h2>

      <a className="btn" href="https://amzn.to/3JBRbbX" rel="nofollow">
        ver en Amazon
      </a>
    </Main>
  );
};

export default Store;
