import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Section = styled.section`
display: flex;
align-items: center;
justify-content: center;
  height: 900px;
  margin: 0;
  padding: 0;
  width: 100%;

  .bg {
    background: url("https://dmicano.s3.us-east-2.amazonaws.com/Splash.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    margin: 0;
    padding: 0;
    width: 98%;
    height: 98%;

    article {
    align-items: center;
    background: #00000077;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    height: 100%;
    text-align: center;
    width: 100%;
    
    h1 {
      font-size: 3rem;
      font-weight: 400;
      margin: 0;
      max-width: 500px;
      padding: 0;
      width: 90%;
    }

    p {
      font-size: 1.3rem;
      line-height: 1.5rem;
      max-width: 600px;
      padding: 0;
      width: 90%;
    }
  }
`;

const DominoSection2 = () => {
  const [t] = useTranslation();
  return (
    <Section>
      <div className="bg">
        <article>
          <h1>{t("dominoSection2.title")}</h1>
          <p>{t("dominoSection2.description")}</p>
        </article>
      </div>
    </Section>
  );
};

export default DominoSection2;
