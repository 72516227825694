// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  // onAuthStateChanged,
  signOut,
  updateProfile,
} from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const signup = ({ email, password, name }) => {
  let user = null;
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      user = userCredential.user;

      if (user)
        updateProfile(user, {
          displayName: name,
        });
      user = userCredential;
    })
    .catch((error) => {
      alert(error.message);
    });

  return user;
};

const signin = async ({ email, password }) => {
  let isSignedIn = false;
  try {
    const cred = await signInWithEmailAndPassword(auth, email, password);
    if (cred) isSignedIn = true;
  } catch (error) {
    console.log(error.message);
    alert(error.message);
  }
  return isSignedIn;
};

const signout = () => {
  signOut(auth)
    .then(() => console.log("user signed out"))
    .catch((ex) => console.log(ex));
};

const getBanner = async () => {
  let banners = [];
  const bannerRef = collection(db, "/banners");

  try {
    const res = await getDocs(bannerRef);
    res.forEach((doc) => banners.push(doc.data()));
  } catch (error) {
    console.log("Erroy: " + error);
  }
  // console.log(banners[0]);
  return banners;
};

// const addClubAddress = (data) => {
//   const clubInfo = doc(db, "clubInfo/");
//   const docData = {
//     address: data.address,
//     apt: data.apt ?? "",
//     city: data.city,
//     state: data.state,
//     zip: data.zip,
//   };

//   setDoc(clubInfo, docData, { merge: true }).then((res) => console.log(res));
// };

// const addNewClub = async (data = {}) => {
//   const clubCollection = doc(db, "clubCollection");
//   const newDoc = await addDoc(clubCollection, data);
// };

export { signup, signin, signout, auth, getBanner };
