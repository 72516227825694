import logo from "../Assets/logo.png";
import appStore from "../Assets/appstore.png";
import googlePlay from "../Assets/google-play-badge.png";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

const sliding = keyframes`
0% {
  background-position: left;
}

100% {
  background-position: right;
}
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--gradient);
  background-size: 1200%;
  background-position: left;
  animation: 20s linear 0s infinite alternate ${sliding};
  width: 100vw;
  height: 90vh;
  min-height: 700px;
  margin: 0;
  padding: 0;

  h1 {
    margin: 50px 0;
    font-size: 3.8rem;
    font-weight: bold;
    max-width: 90%;
    text-align: center;
  }

  section {
    display: flex;
    gap: 10px;

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        transition: transform 300ms ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
`;

const rotate = keyframes`
from {
  transform: rotate(10deg);
}
to {
  transform: rotate(-10deg);
}
`;

const AnimatedImg = styled.div`
  display: flex;
  animation: 1s linear 0s infinite alternate ${rotate};
`;

const AppHeader = () => {
  const { t } = useTranslation("translation");
  return (
    <Header>
      <h1>{t("headerTitle")}</h1>

      <section>
        <AnimatedImg>
          <img width="130" height="130" src={logo} alt="logo" />
        </AnimatedImg>

        <div className="col">
          <a href="https://itunes.apple.com/us/app/domino-apunte/id1385265662?mt=8">
            <img src={appStore} height="60" alt="Apple store" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.dmicano.angel1sma3l.cartondeapunte&hl=en_US">
            <img src={googlePlay} height="70" alt="Google Play" />
          </a>
        </div>
      </section>
    </Header>
  );
};

export default AppHeader;
