import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useUserContext } from "../context/userContext";
import { signout } from "../firebase";
import moment from "moment";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000099;
  position: absolute;
  top: 0;
  left: 0;
`;

const Main = styled.form`
  background: black;
  border-radius: 5%;
  box-shadow: 0px 0px 20px darkred;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 80vh;
  justify-content: space-around;
  max-width: 800px;
  overflow: scroll;
  padding: 30px;
  position: relative;
  width: 80vw;
  z-index: 5;

  h3 {
    margin: 0 auto;
  }

  h5 {
    margin: 10px 0;
  }

  button {
    background: royalblue;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    margin: 10px auto;
    padding: 10px 20px;
    transition: all 333ms ease;
    width: fit-content;

    &:hover {
      background: gray;
    }
  }

  .table-container {
    max-height: 200px;
    overflow: scroll;
  }

  table {
    th {
    }

    td {
      padding: 10px;
    }

    i {
      cursor: pointer;
    }

    input {
      width: 100%;
      background-color: black;
      color: lightgray;
      font-size: 1rem;
      border-width: 0;
      border-bottom-width: 1px;
      outline: none;
    }
  }

  .signout {
    background: transparent;
    border: none;

    &:hover {
      background: none;
    }
  }

  .close {
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    position: fixed;
    right: 15px;
    top: 15px;
  }
`;

let tournaments = [
  {
    date: "Feb 1 2023",
    location: "Santo Domingo",
    time: "12:23",
  },
  {
    date: "March 1 2023",
    location: "Santiago",
    time: "11:30",
  },
  {
    date: "April 8 2024",
    location: "La Vega",
    time: "11:30",
  },
  {
    date: "June 13 2023",
    location: "Santo Domingo",
    time: "11:30",
  },
];

const Dashboard = ({ show, onClose }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [tment, setTment] = useState(tournaments);

  if (!show) return null;

  const tournamentsInfo = tment
    .map((t, i) => {
      return (
        <tr key={i}>
          <td>{t.location}</td>
          <td>{moment(t.date).format(`${t("dashboard.dateformat")}`)}</td>
          <td>{t.time}</td>
        </tr>
      );
    })
    .reverse();

  const NewTournament = () => {
    const [data, setData] = useState({ date: "", time: "", location: "" });

    const handleChange = (e) => {
      e.preventDefault();
      let newData = { ...data };

      const name = e.target.name;
      const value = e.target.value;

      newData[name] = value;
      setData(newData);
    };

    const addTournament = (e) => {
      e.preventDefault();
      let newTms = [...tment];

      newTms.push(data);
      setTment(newTms);
      console.log(tment);
    };

    return (
      <tr>
        <td>
          <input
            required
            onChange={handleChange}
            value={data.location}
            name="location"
            placeholder="Santo Domingo"
          />
        </td>
        <td>
          <input
            required
            onChange={handleChange}
            name="date"
            value={data.date}
            placeholder="mm/dd/yyyy"
            type="date"
          />
        </td>
        <td>
          <input
            required
            onChange={handleChange}
            name="time"
            value={data.time}
            placeholder="03:00pm"
            type="time"
          />
        </td>

        <td>
          <i onClick={addTournament} className="fa-solid fa-plus"></i>
        </td>
      </tr>
    );
  };

  return (
    <Container>
      <Main>
        <i onClick={onClose} className="fa-solid fa-close close"></i>

        <h2>{t("nav.dashboard")}</h2>

        <h3>
          {t("dashboard.welcome")} {user.displayName.toUpperCase()}
        </h3>

        <h5>{t("dashboard.tournaments")}</h5>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>{t("dashboard.location")}</th>
                <th>{t("dashboard.date")}</th>
                <th>{t("dashboard.time")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <NewTournament />
              {tournamentsInfo}
            </tbody>
          </table>
        </div>

        <h5>{t("dashboard.profile")}</h5>

        <button
          className="signout"
          onClick={() => {
            signout();
            onClose();
          }}
        >
          {t("nav.signout")}
        </button>
      </Main>
    </Container>
  );
};

export default Dashboard;
